<template>
  <div class="friends-options p-0 pt-3">
    <nav class="navbar-expand-lg">
      <div class="navbar-nav mx-auto">
        <ul class="mb-0 events-games-tabs">
          <router-link class="nav-link pt-0 pb-1" exact :to="{ name: 'GameEvents', params: { teamId: teamId } }">
            <span>Events</span>
          </router-link>
          <!-- <router-link class="nav-link pt-0 pb-2" exact :to="{ name: 'GameSchedule', params: { teamId: teamId } }">
            <span>Schedule</span>
          </router-link> -->
          <router-link class="nav-link pt-0 pb-1" exact :to="{ name: 'GameTeammates', params: { teamId: teamId } }">
            <span>Teammates</span>
          </router-link>
          <router-link class="nav-link pt-0 pb-1" exact :to="{ name: 'PlayerStatistics', params: { teamId: teamId } }">
            <span>Player Statistics</span>
          </router-link>
          <router-link class="nav-link pt-0 pb-1" exact :to="{ name: 'TeamStatistics', params: { teamId: teamId } }">
            <span>Team Statistics</span>
          </router-link>
        </ul>
      </div>
    </nav>
    <!-- <nav class="navbar-expand-lg">
      <div class="navbar-nav mx-auto">
        <ul class="mb-0">
          <li
            class="nav-link pt-0 pb-2"
            :class="{ 'router-link-exact-active': isActive('Schedule') }"
            @click="changeTab('Schedule')"
          >
            <span>Schedule</span>
          </li>
          <li
            class="nav-link pt-0 pb-2"
            :class="{ 'router-link-exact-active': isActive('Teammates') }"
            @click="changeTab('Teammates')"
          >
            <span>Teammates</span>
          </li>
          <li
            class="nav-link pt-0 pb-2"
            :class="{ 'router-link-exact-active': isActive('Statistics') }"
            @click="changeTab('Statistics')"
          >
            <span>Statistics</span>
          </li>
        </ul>
      </div>
    </nav> -->
  </div>
</template>

<script>
export default {
  name: "TeamDetailsTab",
  data() {
    return {};
  },
 props: {
    teamId: {
        type: String,
    },
 },
  methods: {
    changeTab(tab) {
      this.$root.$emit("tabChange", tab);
    },
    isActive(tab) {
  return this.$route.name.includes(`Game${tab}`);
},
  },
  mounted() {},
};
</script>

<style>
</style>