<template>
    <div>
      <Navbar />
      <div class="setting-main-wrapper">
        <div class="main-left-menu">
          <LeftMenu />
        </div>
        <Banner />
        <div class="shop-side p-md-0">
          <LeftMenuSide :openTabSet="openTabSet" />
          <div class="row no-gutters content-wrap-info">
            <div class="team-details-section-wrap">
              <TeamDetailsTop 
                :selectedRoomTeam="selectedRoomTeam"
                :currentUserId="currentUserId"
                :currentIsAdmin="currentIsAdmin"
                :currentIsFan="currentIsFan"
                :currentTeamIsActive="currentTeamIsActive"
                />
              <TeamDetailsTab :teamId="teamId" />
            </div>
            <div class="team-details-wrap">
              <!-- <div class="add-new-team-btn mb-4">
                <button @click="callModal()">
                  <img :src="add" alt="add-team" />
                  <span>Add Event</span>
                </button>
              </div> -->
              <TeamEventsDetail :selectedRoomTeam="selectedRoomTeam"
              :currentIsAdmin="currentIsAdmin"
              :currentTeamIsActive="currentTeamIsActive"/>

              <!-- <TeamEventsDetailUpdated :selectedRoomTeam="selectedRoomTeam"
              :currentIsAdmin="currentIsAdmin"
              :currentTeamIsActive="currentTeamIsActive"/> -->
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
          
<script>
import TeamDetailsTop from "../components/GameTime/TeamDetailsTop.vue";
import TeamDetailsTab from '../components/GameTime/TeamDetailsTab.vue';
import Navbar from "../components/Header/Navbar.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import LeftMenuSide from "@/components/NewGameTime/LeftMenuSide.vue";
import TeamEventsDetail from "../components/GameTime/TeamEventsDetail.vue";
import * as firestoreService from "../database/firestore";
import axios from "axios";
// import TeamEventsDetailUpdated from "../components/GameTime/TeamEventsDetailUpdated.vue";


export default {
  name: "GameEvent",

  components: {
    Navbar,
    LeftMenu,
    TeamDetailsTop,
    TeamDetailsTab,
    TeamEventsDetail,
    Banner,
    LeftMenuSide
  },

  data() {
    return {
      activeAccordionIndex: -1,
      teamId: '',
      currentIsAdmin: "0",
      currentIsFan: "0",
      currentTeamIsActive: 1,
      selectedRoomTeam: {},
      currentUserId: "",
      userId: localStorage.getItem("userId"),
      allUsers: [],
      gameLineUps: [],
      showLoader: 0,
      reverse: false,
      sortKey: "",
      page: 1,
      lastPage: 1,
      showLoader1: false,
      teamLoader:1,
      openTabSet: 2,
      add: require("../assets/Svg/add.svg"),
    };
  },
  methods: {
    async getCurrentChatId() {
      if (this.currentUserId == "") {
        const promiseUser = await firestoreService.getUserCid(this.userId);
        this.foundCurrentUser = promiseUser;
        if (this.foundCurrentUser.data[0].id != undefined) {
          this.currentUserId = this.foundCurrentUser.data[0].id;
        } else {
          this.getCurrentChatId();
        }
      }
    },
    async getTeamData() {
      this.teamLoader = 1;
      const rawRoom = [];
      const promise = await firestoreService.getRoomById(this.teamId);
      rawRoom.push(promise);

      const foundRoom = await Promise.all(rawRoom);
      let usersData = {};
      Object.keys(foundRoom).forEach((key) => {
        const teamData = foundRoom[key];
        usersData = teamData.users;
        teamData.users = {};
        this.selectedRoomTeam = teamData;
      });

      // check role if team exits or user is member
      let roles = this.selectedRoomTeam.userRole;
      console.log(roles);
      console.log(usersData);
      if (usersData != undefined) {
        var admincount = 0;
        for (const userRole of roles) {
          if (userRole.id == this.currentUserId) {
            admincount++;
          }
        }
        if (admincount == 0) {
          this.$router.push({ name: "PageNotFound" });
        }
      } else {
        this.$router.push({ name: "PageNotFound" });
      }

      await this.makeUserArray(usersData);
      const users = [];
      usersData.forEach((userId) => {
        const foundUser = this.allUsers.find((user) => user?._id === userId);
        if (foundUser) {
          var chechUrl;
          var url =
            this.$userProfileStorageURL +
            foundUser.c_id +
            "/profile_picture.jpg?v=" +
            this.time;

          this.urlExist(url, (exists) => {
            if (exists) {
              chechUrl = "yes";
              foundUser.avatar = url;
              console.log(chechUrl);
            } else {
              chechUrl = "no";
              foundUser.avatar = require("../assets/Svg/member.svg");
            }
          });
          users.push(foundUser);
        }
      });

      this.selectedRoomTeam.users = users;

      // assign admin role
      let tempMembers = this.selectedRoomTeam.users;

      // Set Users Role and Find if current User is admin
      for (const role of roles) {
        for (const member of tempMembers) {
          if (role.id == member.id) {
            member.adminRole = role.admin;
            member.fanRole = role.fan;
            member.roleStatus = role.status;
            member.uniform = role.uniform;
            member.bat_size = role.bat_size;
            member.bat_details = role.bat_details;
            member.hat_size = role.hat_size;
            member.shirt_size = role.shirt_size;
            member.pant_size = role.pant_size;
            var chechUrl;
            var url =
              this.$userProfileStorageURL +
              member.c_id +
              "/profile_picture.jpg?v=" +
              this.time;
            this.urlExist(url, (exists) => {
              if (exists) {
                chechUrl = "yes";
                member.avatar = url;
                console.log(chechUrl);
              } else {
                chechUrl = "no";
                member.avatar = require("../assets/Svg/member.svg");
              }
            });
          }
          if (
            member.id == this.currentUserId &&
            member.adminRole == "1"
          ) {
            this.currentIsAdmin = "1";
          }
          if (
            member.id == this.currentUserId &&
            member.adminRole == "0"
          ) {
            this.currentIsAdmin = "0";
          }
          if (
            member.id == this.currentUserId &&
            member.fanRole == "1"
          ) {
            this.currentIsFan = "1";
          }
          if (
            member.id == this.currentUserId &&
            member.fanRole != "1"
          ) {
            this.currentIsFan = "0";
          }

          // assign current user team status
          if (
            member.id == this.currentUserId &&
            member.roleStatus == 1
          ) {
            this.currentTeamIsActive = 1;
          }
          if (
            member.id == this.currentUserId &&
            member.roleStatus == 2
          ) {
            this.currentTeamIsActive = 2;
          }
        }
      }

      this.selectedRoomTeam.roomId = this.selectedRoomTeam.id;
      this.teamLoader = 0;
    },
    async makeUserArray(users) {
      const roomUserIds = users;

      const rawUsers = [];
      roomUserIds.forEach((userId) => {
        const promise = firestoreService.getUser(userId);
        rawUsers.push(promise);
      });
      this.allUsers = [...this.allUsers, ...(await Promise.all(rawUsers))];
    },

    // URL exist ----------------------------------------------------------------
    urlExist(url, callback) {
      const img = new Image();
      img.src = url;

      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };

        img.onerror = () => {
          callback(false);
        };
      }
    },
    async fetchLineups() {
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("team_id", this.teamId);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/getStatsForTeam", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameLineUps = response.data.data.gameLineUps;
          this.totalNumberOfPlayers = response.data.data.gameLineUps.length;
          const userRoles = this.selectedRoomTeam.userRole;
          const rawUsers = [];
          this.showLoader = 0;

          const fetchUserData = async (userId) => {
            const userFireBase = await firestoreService.getUser(userId);

            rawUsers.push(userFireBase);
          };

          (async () => {
            const gameLineUpsWithUserRole = [];
            for (const user of this.gameLineUps) {
              await fetchUserData(user.stats.player_id);

              const userRole = userRoles.find(
                (userRole) => userRole.id === user.stats.player_id
              );
              const userData = rawUsers[rawUsers.length - 1];

              gameLineUpsWithUserRole.push({
                ...user,
                userRole,
                username: userData.username,
              });
            }
            this.showLoader = 0;

            this.gameLineUps = gameLineUpsWithUserRole;
            this.gameLineUps.sort((a, b) => a.position_index - b.position_index);
          })();
        });
    },
  },
  mounted() {
    var url = window.location.pathname;
    console.log(url);
    var segment_array = url.split("/");
    this.teamId = segment_array.pop();
    if (this.teamId) {
      this.getCurrentChatId();
      this.getTeamData();
      // this.fetchLineups();
    }
    else {
      this.$router.push({ name: "PageNotFound" });
    }

    this.$root.$on("gameTimeStatisticsReload", () => {
      if (this.teamId) {
        this.getCurrentChatId();
        this.getTeamData();
      }
    });

    this.$root.$on("openTabSetting", (id) => {
            this.openTabSet = id;
        });
  },
};
</script>
  <style scoped>
    .single-box-score-wrap .sub-setting-options {
      left: inherit;
    }
    .sub-setting-options {
    top: 8%;
    right: 43px;
  }
  
  .sort-icon {
  width: 14px;
  margin-left: 2px;
  padding-bottom: 2px;
  cursor: pointer;
  }
  .chat-filter-options {
  background: #cce6fd;
  border-radius: 5px;
  height: 35px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  }
  .filter-counter {
  background: #254c72;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  margin: 0 5px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  }
  .blocker {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  bottom: 0;
  right: 0;
  content: " ";
  background: rgba(0, 0, 0, 0.5);
  }
  .pinned-options {
  top: 9%;
  left: inherit;
  margin-right: -13%;
  }
  .no-game-section{
  height: 150px!important;
  }.no-products-in-shop {
    height: auto!important;
  }
  </style>