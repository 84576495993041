<template>
  <div class="event-section-wrap pt-3">
    <div class="row m-0">
      <div
        class="col-md-2 form-group control-group mb-0 d-flex align-items-center justify-content-end"
      >
        <select
          class=""
          id="eventType"
          v-model="filter.eventType"
          @change="getTeamEvents()"
          :disabled="isLoading"
        >
          <option value="" selected>Select Event Type</option>
          <option
            v-for="option in filteredEventTypeOptions"
            :value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
        <span
          :style="{ display: isLoading ? 'none' : 'block' }"
          v-if="filter.eventType"
          class="clear-icon"
          @click="clearEventType()"
          ><img src="../../assets/cross-icon.png"
        /></span>
      </div>
      <div
        class="col-md-2 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end"
      >
        <select
          class=""
          v-model="filter.associationFilter"
          @change="getTeamEvents()"
          :disabled="isLoading"
        >
          <option value="" selected>Select Association</option>
          <option
            v-for="option in associationOptions"
            :value="option"
            :key="option"
          >
            {{ option }}
          </option>
        </select>
        <span
          :style="{ display: isLoading ? 'none' : 'block' }"
          v-if="filter.associationFilter"
          class="clear-icon"
          @click="clearAssociation()"
          ><img src="../../assets/cross-icon.png"
        /></span>
      </div>
      <div
        class="col-md-2 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end"
      >
        <select
          class=""
          v-model="filter.stateFilter"
          @change="getTeamEvents()"
          :disabled="isLoading"
        >
          <option value="" selected>Select States</option>
          <option
            v-for="option in stateOptions"
            :value="option"
            :key="option.name"
          >
            {{ option.abbreviation }}
          </option>
        </select>
        <span
          :style="{ display: isLoading ? 'none' : 'block' }"
          v-if="filter.stateFilter"
          class="clear-icon"
          @click="clearState()"
          ><img src="../../assets/cross-icon.png"
        /></span>
      </div>
      <div class="col-md-2 px-1 form-group mb-0">
        <date-picker
          :open="open"
          @close="open = false"
          :editable="false"
          @click.native="handleClick"
          v-model="filter.startDateFilter"
          valueType="format"
          format="DD/MM/YYYY"
          placeholder="dd/mm/yyyy"
          @input="checkDateInput"
          @change="getTeamEvents()"
          :disabled="isLoading"
        >
          <i slot="icon-calendar" class="cursor-pointer">
            <img src="../../assets/images/calendar-input.png" />
          </i>
        </date-picker>
      </div>
      <div class="col-md-2 px-1 form-toggle control-group mb-0">
        <div class="h-100 d-flex justify-content-between align-items-center">
          <div>
            <span>Show All Events</span>
          </div>
          <toggle-button
            class="m-0"
            v-model="filter.eventStatus"
            :value="filter.eventStatus"
            :sync="true"
            :width="34"
            @change="togglePastEventsFilter()"
            :disabled="isLoading"
          />
        </div>
      </div>
    </div>

    <b-row class="no-gutters" v-if="teamEventInfo.length > 0">
      <div class="col-12 products-column mt-3">
        <div class="event-card">
          <div class="table-responsive mb-0">
            <b-table
              :items="sortedEvents"
              :fields="fields"
              hover
              responsive
              class="b-datatable"
             :sort-key="sortKey"
             :sort-direction="reverse ? 'desc' : 'asc'"
             @sort-changed="handleSortChanged"
            >
            

              <template v-slot:cell(event)="data">
                <td class="p-0">
                  <div>
                    {{ data.item.eventType }}
                  </div>
                  <div>
                    {{ data.item.eventName }}
                  </div>
                </td>
              </template>
              <!-- Custom slot for the action column -->
              <template v-slot:cell(action)="data">
                <div class="content action-column text-center">
                  <img
                    src="../../assets/chevron-up.png"
                    class="cursor-pointer"
                    v-if="activeAccordionId === data.item.id"
                    @click="closeToggleAccordion(data.item.id)"
                  />
                  <img
                    src="../../assets/chevron-down.png"
                    class="cursor-pointer"
                    v-else
                    @click="toggleAccordion(data.item, data.item.id)"
                  />
                </div>
              </template>
              <template v-slot:cell(association)="data">
                <td class="p-0" v-if="shouldDisplayAssociation(data.item)">
                  {{ data.item.association }}
                </td>
              </template>
              <template v-slot:cell(location)="data">
                <div
                  class="event-location"
                  v-if="
                    (data.item.status == 1 &&
                      data.item.address != null &&
                      data.item.address != '' &&
                      data.item.address != 'null') ||
                    (data.item.city != null &&
                      data.item.city != '' &&
                      data.item.city != 'null') ||
                    (data.item.state != null &&
                      data.item.state != '' &&
                      data.item.state != 'null')
                  "
                >
                  <img
                    class="mr-2"
                    src="../../assets/images/location.png"
                    @click="openGoogleMap(data.item)"
                  />
                  <span class="content" @click="openGoogleMap(data.item)">
                   {{ data.item.city }},{{ data.item.state }}
                  </span>
                </div>
                <div v-else>
                  <!-- Display the medium property -->
                  Online Event
                </div>
              </template>

              <!-- Row details slot for displaying games -->
              <template v-slot:row-details="row">
                <b-container v-if="row.item._showDetails">
                  <b-row class="my-2">
                    <b-col class="col-12">
                      <div class="row no-gutters" v-if="showGameShimmer === 1">
                        <div class="col-12 p-0">
                          <content-placeholders :rounded="true">
                            <content-placeholders-heading :img="false" />
                          </content-placeholders>
                          <br />
                          <content-placeholders :rounded="true">
                            <content-placeholders-heading :img="false" />
                          </content-placeholders>
                        </div>
                      </div>
                      <b-table
                        v-else-if="gameList && gameList.length > 0"
                        :items="gameList"
                        :fields="gameFields"
                        responsive
                        class="b-datatable"
                      >
                        <!-- <template v-slot:cell(game_action)="game">
                          <td class="content text-center cursor-pointer">
                            <img
                              class="eye-icon"
                              src="../../assets/images/eye_see.png"
                              alt="eye"
                              @click="callModal(game.item, row.item)"
                            />
                          </td>
                        </template> -->
                        <template v-slot:thead-top>
                          <b-tr class="add-game-tr">
                            <b-td colspan="4">
                              <!-- <div class="products-list-shim" v-if="teamLoader == 1">
                                <content-placeholders :rounded="true">
                                  <content-placeholders-text :lines="2" />
                                </content-placeholders>
                              </div> -->

                              <!-- <div v-else> -->
                              <div >
                                <div class="custom-header-column">
                                  <h6>Games</h6>
                                </div>
                              </div>
                            </b-td>

                            <b-td colspan="4">
                              <div class="custom-header-column d-flex justify-content-end" v-if="currentIsAdmin == '1' && selectedRoomTeam.teamType == 'sports' ">
                                <NewGame :newGameProps="newGameProps" />
                              </div>
                            </b-td>
                          </b-tr>
                        </template>

                        <template v-slot:cell(game_action)="game">
                              <td class="content p-0 text-center">
                                  <router-link :to="`/score-game/${game.item.team_id}-${game.item.guid}`">
                                      <img
                                          class="eye-icon cursor-pointer"
                                          src="../../assets/images/eye_see.png"
                                          alt="eye"
                                      />
                                  </router-link>
                              </td>
                          </template>
                        <template v-slot:cell(name)="data">
                          <div class="d-flex gap-16">
                            <div
                              class="live-league position-relative"
                              v-if="data.item.game_live == 1"
                            >
                              <div class="inner-sec m-0">
                                <div class="live-tag">
                                  <span>Live</span>
                                </div>
                              </div>
                            </div>
                            <span class="content">{{ data.item.name }}</span>
                          </div>
                        </template>
                        <template v-slot:cell(start_date)="data">
                          <td class="content p-0">
                            {{ data.item.exactDateFormat }}
                          </td>
                        </template>
                        <template v-slot:cell(park_name)="data">
                          <td class="content p-0">
                            <p>{{ data.item.park_name }}</p>
                            <p
                              class="content"
                              v-if="data.item.park_address !== null"
                            >
                              <img
                                src="../../assets/images/location.png"
                                class="mr-2"
                                @click="openGoogleMap(data.item)"
                              />
                              {{ data.item.park_address }}
                            </p>
                          </td>
                        </template>
                        <template v-slot:cell(opponent_name)="data">
                          <td class="content p-0">
                            {{ data.item.opponent_name }}
                            <p
                              class="content"
                              v-if="
                                data.item.comment !== null &&
                                data.item.comment !== '' &&
                                data.item.comment !== 'null'
                              "
                            >
                              {{ data.item.comment }}
                            </p>
                          </td>
                        </template>
                      </b-table>
                      <div v-if="showGameShimmer == 0 && gameList.length == 0">
                        <!-- <div class="products-list-shim" v-if="teamLoader == 1">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text :lines="2" />
                          </content-placeholders>
                        </div> -->

                        <div class="d-flex justify-content-between" >
                          <h6>Games</h6>
                          <NewGame
                            v-if="currentIsAdmin == '1' && selectedRoomTeam.teamType == 'sports'"
                            :newGameProps="newGameProps"
                          />
                        </div>
                        <div class="no-game-section rounded">
                          <div class="no-content">
                            <div class="img">
                              <img src="../../assets/images/games.png" alt="" />
                            </div>
                            <p class="text">
                              There are no games added for this event yet
                            </p>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
            </b-table>
            <content-placeholders :rounded="true" v-if="showLoader1">
              <content-placeholders-heading :img="false" />
            </content-placeholders>
          </div>
        </div>
      </div>
    </b-row>

    <div
      v-if="teamEventInfo.length"
      v-observe-visibility="handleScrolledToButtom"
    ></div>

    <div
      class="no-products-in-shop"
      v-if="teamEventInfo.length === 0 && showLoader === 0"
    >
      <img src="../../assets/images/no-product.png" alt />
      <p class="header">No event found.</p>
      <p class="info">
        There are no events to display in the selected category.
      </p>
    </div>

    <div
      class="products-list-shim mt-3"
      v-if="teamEventInfo.length == 0 && showLoader == 1"
    >
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
  </div>
</template>
<script>
import axios from "axios";
let states = require("../../assets/js/state");
let eventType = require("../../assets/js/eventType");
import NewGame from "../Teams/Games/NewGame.vue";

export default {
  name: "TeamEventsDetail",
  components: {
    NewGame,
  },

  data() {
    return {
      newGameProps: {},
      activeAccordionId: -1,
      ComponentName: "Events",
      activeAccordionIndex: -1,
      reverse: false,
      sortKey: "",
      showLoader1: false,
      showGameShimmer: 0,
      gameList: [],
      teamId: "",
      showLoader: 0,
      teamEventInfo: [],
      page: 1,
      lastPage: 1,
      total: 0,
      eventTo: 0,
      open: false,
      fields: [
      {
          key: "startDateForField",
          label: "Date",
          sortable: true,
          formatter: (value, key, item) => {
              const startDate = new Date(item.exactStartDate);
              const endDate = new Date(item.exactEndDate);
              const startDateString = startDate.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
              });
              const endDateString = endDate.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
              });
              const startYearString = startDate.getFullYear();
              const endYearString = endDate.getFullYear();

              if (startYearString === endYearString) {
                  return `${startDateString} to ${endDateString}, ${startYearString}`;
              } else {
                  return `${startDateString}, ${startYearString} to ${endDateString}, ${endYearString}`;
              }
          },
        },
        { key: "event", label: "Event", sortable: true, sortBy: (item) => `${item.eventType} ${item.eventName}` },
        { key: "association", label: "Association", sortable: true },
        {
          key: "location",
          label: "Location",
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            return `${item.address || ""}, ${item.city || ""}, ${
              item.state || ""
            }`;
          },
          sortBy: (item) => {
            const location =
              (item.address || "") +
              " " +
              (item.city || "") +
              " " +
              (item.state || "");
            return location.toLowerCase();
          },
        },
        { key: "action", label: "Action", class: "text-center" },
      ],
      gameFields: [
        { key: "name", label: "Game Name" },
        { key: "start_date", label: "Start Date" },

        {
          key: "park_name",
          label: "Park Name",
        },

        { key: "opponent_name", label: "Opponent Name" },

        {
          key: "game_action",
          label: "Action",
          // class: "d-flex justify-content-end",
        },
      ],
      isLoading: false,
      filter: {
        eventType: "",
        associationFilter: "",
        stateFilter: "",
        startDateFilter: "",
        eventStatus: false,
      },
      stateOptions: states,
      associationOptions: [],
      eventTypeOptions: eventType,
      filterApplied: false,
    };
  },
  props: {
    currentIsAdmin: {
      type: String,
    },
    selectedRoomTeam: {
      type: Object,
    },
  },
  // components: {
  //   EventsFilter,
  // },
  watch: {
    sortBy: function (newSortBy, oldSortBy) {
      if (newSortBy !== oldSortBy) {
        // Close the accordion when sorting is applied
        this.closeToggleAccordion(this.activeAccordionId);
      }
    },
  },
  computed: {
    filteredEventTypeOptions() {
        return this.eventTypeOptions.filter(option => option.status === 1);
    },
    sortedEvents() {
      const events = this.teamEventInfo.slice();
      if (this.sortKey === "event") {
      events.sort((a, b) => {
        const eventA = `${a.eventType} ${a.eventName}`;
        const eventB = `${b.eventType} ${b.eventName}`;
        return this.reverse
          ? eventB.localeCompare(eventA)
          : eventA.localeCompare(eventB);
      });
    } else if (this.sortKey === "exactStartDate") {
        events.sort((a, b) => {
          const dateA = new Date(a[this.sortKey]);
          const dateB = new Date(b[this.sortKey]);

          if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
            return 0; // handle invalid dates
          }

          return this.reverse
            ? dateB.getTime() - dateA.getTime()
            : dateA.getTime() - dateB.getTime();
        });
      } else if (this.sortKey === "location") {
        events.sort((a, b) => {
          const locationA =
            (a.address || "") + " " + (a.city || "") + " " + (a.state || "");

          const locationB =
            (b.address || "") + " " + (b.city || "") + " " + (b.state || "");

          return this.reverse
            ? locationB.localeCompare(locationA)
            : locationA.localeCompare(locationB);
        });
      } else if (this.sortKey) {
        events.sort((a, b) => {
          const fieldA = a[this.sortKey];
          const fieldB = b[this.sortKey];

          if (typeof fieldA === "string" && typeof fieldB === "string") {
            return this.reverse
              ? fieldB.localeCompare(fieldA)
              : fieldA.localeCompare(fieldB);
          } else if (typeof fieldA === "number" && typeof fieldB === "number") {
            return this.reverse ? fieldB - fieldA : fieldA - fieldB;
          } else {
            return 0; // handle other types of sorting if needed
          }
        });
      }
      return events;
    },
  },

  methods: {
    handleSortChanged({ sortBy, sortDesc }) {
    this.sortKey = sortBy;
    this.reverse = sortDesc;
  },
    handleClick(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.open = true;
      }
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    shouldDisplayAssociation(item) {
      return (
        this.selectedRoomTeam.sportType === "Softball (Slow Pitch)" ||
        this.selectedRoomTeam.sportType === "Softball (Fast Pitch)" ||
        (this.selectedRoomTeam.sportType === "Softball" &&
          item.association != null &&
          item.association !== "" &&
          item.association !== "null")
      );
    },
    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (isVisible) {
        this.page++;
        this.getTeamEvents();
      }
    },
    toggleAccordion(event, eventId) {
      this.newGameProps = { event };
      if (this.activeAccordionId === eventId) {
        this.teamEventInfo.forEach((teamEventInfo) => {
          if (teamEventInfo.id === eventId) {
            teamEventInfo._showDetails = false;
          }
        });
        this.activeAccordionId = -1;
      } else {
        this.teamEventInfo.forEach((teamEventInfo) => {
          teamEventInfo._showDetails = teamEventInfo.id === eventId;
        });
        this.activeAccordionId = eventId;
        this.getGames(event);
      }
    },

    closeToggleAccordion(eventId) {
      this.teamEventInfo.forEach((teamEventInfo) => {
        if (teamEventInfo.id === eventId) {
          teamEventInfo._showDetails = false;
        }
      });
      this.activeAccordionId = -1;
    },
    async getGames(event) {
      this.showGameShimmer = 1;
      this.gameList = [];
      var formData = new FormData();
      formData.append("event_id", event.id);
      formData.append("team_id", event.team_id);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/gameListing", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameList = response.data.data;
          event.showGameList = true;
          this.showGameShimmer = 0;
        });
    },
    callModal(game, event) {
      var combineUrl = event.team_id + "-" + game.guid;
      const urlToOpen = `/score-game/${combineUrl}`;
      window.location.href = urlToOpen;
    },
    sortBy(sortKey) {
      if (this.sortKey === sortKey) {
        this.reverse = !this.reverse;
      } else {
        this.sortKey = sortKey;
        this.reverse = false;
      }
    },
    togglePastEventsFilter() {
      this.teamEventInfo = [];
      this.page = 1;
      this.getTeamEvents(); // Always call calendarData() method to fetch data
    },
    async getTeamEvents() {
      this.showLoader = 1;
      this.showLoader1 = true;
      this.isLoading = true;

      let formattedStartDate = "";
      if (this.filter.startDateFilter) {
        const startDateParts = this.filter.startDateFilter.split("/");
        formattedStartDate = `${startDateParts[2]}/${startDateParts[1]}/${startDateParts[0]}`;
      }
      
      const formData = new FormData();
      formData.append("team_id", this.teamId);

      formData.append("event_type", this.filter.eventType);
      formData.append("startDate", formattedStartDate);
      formData.append("association", this.filter.associationFilter);
      if (this.filter.stateFilter) {
        formData.append("region", this.filter.stateFilter.name);
        formData.append("abbreviation", this.filter.stateFilter.abbreviation);
      }
      if (this.filter.eventStatus != "") {
        formData.append("eventStatus", this.filter.eventStatus);
      } else {
        formData.append("eventStatus", false);
      }
      if (
        (this.filter.eventType ||
          this.filter.startDateFilter ||
          this.filter.associationFilter ||
          this.filter.stateFilter) &&
        !this.filterApplied
      ) {
        this.page = 1;
        this.teamEventInfo = [];
        this.filterApplied = true;
      }

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}event/teamEventListing?page=${this.page}`,
          formData
        );

        if (response.data.statusCode === 200) {
          const newEventData = response.data.data.data;

          if (this.page === 1) {
            this.teamEventInfo = [];
          }
          this.teamEventInfo = this.teamEventInfo.concat(newEventData);
         
            this.teamEventInfo.sort((a, b) => {
              const dateAStart = new Date(a.exactStartDate);
              const dateBStart = new Date(b.exactStartDate);
              const dateAEnd = new Date(a.exactEndDate);
              const dateBEnd = new Date(b.exactEndDate);
              
              // Compare start dates first
              if (dateAStart.getTime() !== dateBStart.getTime()) {
                  return dateAStart - dateBStart;
              } else {
                  // If start dates are the same, compare end dates
                  return dateAEnd - dateBEnd;
              }
          });

          this.lastPage = response.data.data.last_page;
        } else {
          console.error(
            "Error:",
            response.data.message || "Unknown error occurred"
          ); // Log error message
          this.errorMessage = response.data.message || "Unknown error occurred";
        }
      } catch (error) {
        console.error("Failed to fetch data:", error); // Log error message
        this.errorMessage = "Failed to fetch data";
      } finally {
        this.showLoader = 0;
        this.showLoader1 = false;
        this.isLoading = false;
      }
    },
    getFilters() {
      (this.teamEventInfo = []), (this.showLoader = 1);
      this.showLoader1 = true;
      this.isLoading = true;
      const formData = new FormData();
      formData.append("team_id", this.teamId);

      formData.append("event_type", this.filter.eventType);
      formData.append("startDate", this.filter.startDateFilter);
      formData.append("association", this.filter.associationFilter);
      formData.append("region", this.filter.stateFilter);
      if (this.filter.eventStatus != "") {
        formData.append("eventStatus", this.filter.eventStatus);
      } else {
        formData.append("eventStatus", false);
      }
      if (
        this.filter.eventType ||
        this.filter.startDateFilter ||
        this.filter.associationFilter ||
        this.filter.stateFilter
      ) {
        this.page = 1;
        this.teamEventInfo = [];
      }

      axios
        .post(
          `${process.env.VUE_APP_API_URL}event/teamEventListing?page=${this.page}`,
          formData,
          {}
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.total = response.data.data.total;
            this.teamEventInfo.push(...response.data.data.data);
            this.teamEventInfo.sort((a, b) => {
              const dateAStart = new Date(a.exactStartDate);
              const dateBStart = new Date(b.exactStartDate);
              const dateAEnd = new Date(a.exactEndDate);
              const dateBEnd = new Date(b.exactEndDate);
              
              // Compare start dates first
              if (dateAStart.getTime() !== dateBStart.getTime()) {
                  return dateAStart - dateBStart;
              } else {
                  // If start dates are the same, compare end dates
                  return dateAEnd - dateBEnd;
              }
          });
            this.lastPage = response.data.data.last_page;
            this.showLoader = 0;
            this.showLoader1 = false;
            this.isLoading = false;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
            this.showLoader1 = false;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          (this.errorMessage = "Failed to fetch data"), error;
          this.showLoader = 0;
          this.showLoader1 = false;
          this.isLoading = false;
        });
    },
    clearEventType() {
      this.filter.eventType = "";
      this.page = 1;
      this.filterApplied = false;
      this.teamEventInfo = [];
      this.getTeamEvents();
    },
    clearAssociation() {
      this.filter.associationFilter = "";
      this.page = 1;
      this.filterApplied = false;
      this.teamEventInfo = [];
      this.getTeamEvents();
    },
    clearState() {
      this.filter.stateFilter = "";
      this.page = 1;
      this.filterApplied = false;
      this.teamEventInfo = [];
      this.getTeamEvents();
    },
    checkDateInput() {
      if (!this.filter.startDateFilter) {
        this.filter.startDateFilter = "";
        this.page = 1;
        this.filterApplied = false;
        this.teamEventInfo = [];
        this.getTeamEvents();
      }
    },
    getAssociation() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getAssociation?search=${""}`,
          this.details,
          {}
        )
        .then((response) => {
          this.associationOptions = [];
          response.data.data.association.forEach((element) => {
            this.associationOptions.push(element.association_name);
          });
        });
    },
  },
  mounted() {
    var url = window.location.pathname;
    var segment_array = url.split("/");
    this.teamId = segment_array.pop();
    if (this.teamId) {
      this.getAssociation();
      this.getTeamEvents();
    }
    this.$root.$on("refreshEventGame", (event) => {
      this.getGames(event);
    });
  },
};
</script>
<style scoped>
.table thead th {
  min-width: 150px;
}
.eye-icon {
  width: 30px;
}
.no-game-section {
  height: 150px !important;
}
.no-products-in-shop {
  height: auto !important;
}
.sort-icon {
  width: 14px;
  margin-left: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}
.form-group select {
  border: 1px solid #ced4da !important;
}
</style>